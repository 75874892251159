let fadeInElements = document.querySelectorAll(".js-fade-in");

// Intersection Observerの設定
const configureIntersectionObserver = (callback) => {
  return new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: "0%",
      threshold: 0,
    }
  );
};

// フェードインのIntersection Observer
const fadeObserve = configureIntersectionObserver((target) => {
  target.classList.add("js-active-fade");
});

// .js-fade クラスを持つ要素を取得して Intersection Observer を設定
const fadeElements = document.querySelectorAll(".js-fade");
fadeElements.forEach((element) => fadeObserve.observe(element));

console.log("あああ");
